<script type="text/babel">
import BModelMixin from "@/components/generic/BModelMixin";
import BSwitch from "@/components/generic/BSwitch";

export default {
  name: "submission-trigger-inverter",
  components: { BSwitch },
  mixins: [BModelMixin],
  props: {
    value: {
      type: Boolean,
      required: false
    },
    name: {
      type: String,
      required: true
    }
  }
};
</script>
<template>
  <div class="d-flex">
    <div class="flex-10 pr-3">
      <slot
        v-bind="{
          hint: internalValue ? `${name} CAN NOT be one of these.` : undefined
        }"
      />
    </div>
    <div class="flex-1">
      <b-switch v-model="internalValue" label="Invert" />
    </div>
  </div>
</template>
