import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import state from "./state";

export default () => {
  return {
    namespaced: true,
    state: state(),
    getters,
    actions,
    mutations
  };
};
