import { isNil, kebabCase } from "lodash";
import { DELETE, FETCH, SAVE } from "./action-types";
import { SET_IS_LOADING, SET_MODEL } from "./mutation-types";
import { denormalize } from "normalizr";

export default (
  apiModule,
  schema,
  modelTransformerFn = state => state.model,
  updateModelFn = ({ commit, entities, result }) => {
    // Get the entity, using the schema's key as entity name.
    const entity = entities[schema.key][result];
    // Denormalize the entity
    const model = denormalize(entity, schema, entities);
    // Set the model.
    commit(SET_MODEL, model);
  }
) => ({
  async [SAVE]({ state, commit, dispatch }) {
    // Flag module as loading.
    commit(SET_IS_LOADING, true);
    // Determine whether this is a create or update call.
    const isCreate = isNil(state.model.id);
    // Transform the model for creating / updating
    let model = modelTransformerFn(state);
    // Make the call, depending on previous determination.
    const { entities, result } = isCreate
      ? await apiModule.create(model)
      : await apiModule.update(state.model.id, model);
    // Update the model with the data from the result.
    updateModelFn({ commit, dispatch, entities, result });
    // Flag the module as not loading.
    commit(SET_IS_LOADING, false);
    // Notify of success.
    dispatch(
      "snackbar/addCrudSuccess",
      { entityName: kebabCase(schema.key).replace("-", " "), isCreate },
      { root: true }
    );
    // Return results.
    return result;
  },
  async [FETCH]({ commit, dispatch }, { id }) {
    try {
      // Flag module as loading.
      commit(SET_IS_LOADING, true);
      // Fetch the data through the provided apiModule.
      const { entities, result } = await apiModule.fetch(id);
      // Update the model with the data from the result.
      updateModelFn({ commit, dispatch, entities, result });
      // Flag the module as not loading.
      commit(SET_IS_LOADING, false);
      // Return results.
      return result;
    } catch (throwable) {
      // Log where the error occurred to the user.
      console.error(
        `Error occurred within the ${schema.key} CRUD module fetch action.`
      );
      // Throw the error.
      throw throwable;
    }
  },
  async [DELETE]({ dispatch }, { id }) {
    const { result } = await apiModule.remove(id);
    const entityName = kebabCase(schema.key).replace("-", " ");

    dispatch(
      "snackbar/addItem",
      {
        text: `${entityName} deleted successfully.`,
        color: "success"
      },
      { root: true }
    );

    return result;
  }
});
