import crudGetters from "@/store/templates/b-crud/getters";
import { IS_CHANGED } from "@/store/templates/b-crud/getter-types";
import { isChanged } from "@/store/change";

export default {
  ...crudGetters,
  [IS_CHANGED]: (state, getters) =>
    isChanged(state.model, state.initialModel) ||
    getters[`ruleTriggerGroups/${IS_CHANGED}`],
  separationRule: state => state.model,
  id: state => state.model.id,
  name: state => state.model.name,
  isActive: state => state.model.isActive
};
