export default triggerGroups =>
  triggerGroups.map(triggerGroup => ({
    ...triggerGroup,
    // Override triggers
    triggers: triggerGroup.triggers.map(trigger => ({
      ...trigger,
      // Map countries to array of ids
      countries: trigger.countries.map(({ id }) => id),
      // Map languages to array of ids
      languages: trigger.languages.map(({ id }) => id),
      // Map subjects to array of ids
      subjects: trigger.subjects.map(({ id }) => id),
      // Map triggerQuestions to leave out superfluous data
      triggerQuestions: trigger.triggerQuestions.map(
        ({ id, type, question, options }) => ({
          id,
          type,
          question: question.id,
          options: options.map(({ id }) => id)
        })
      )
    }))
  }));
