import { REMOVE_RULE_TRIGGER } from "./action-types";
import {
  REMOVE_RULE_TRIGGER_FROM_GROUP,
  REMOVE_RULE_TRIGGER_GROUP
} from "@/store/modules/rule-trigger-groups/mutation-types";

export default {
  [REMOVE_RULE_TRIGGER]({ commit }, payload) {
    commit(REMOVE_RULE_TRIGGER_FROM_GROUP, payload);
    if (payload.triggerGroup.triggers.length <= 0) {
      commit(REMOVE_RULE_TRIGGER_GROUP, payload.triggerGroup);
    }
  }
};
