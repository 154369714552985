<script type="text/babel">
import BAutocomplete from "@/components/generic/BAutocomplete";
import BInputHighlightMixin from "@/components/generic/BInputHighlightMixin";
import BSelectWrapperMixin from "@/components/generic/BSelectWrapperMixin";
import AuthUserLocaleMixin from "@/components/generic/AuthUserLocaleMixin";

export default {
  name: "b-autocomplete-question",
  mixins: [BSelectWrapperMixin, BInputHighlightMixin, AuthUserLocaleMixin],
  components: { BAutocomplete },
  props: {}
};
</script>

<template>
  <b-autocomplete
    v-model="internalValue"
    v-bind="selectProperties"
    store="b-question-select"
    :label="multiple ? 'Questions' : 'Question'"
    :item-text="`label.${authUserLocaleCode}`"
  >
    <template v-slot:item="{ item }">
      <v-list-tile-content>
        <v-list-tile-title
          v-html="addSearchHighlightMarkup(item.label[authUserLocaleCode])"
        />
        <v-list-tile-sub-title v-text="item.name" />
      </v-list-tile-content>
    </template>
  </b-autocomplete>
</template>
