var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"white"}},[_c('crud-header',{attrs:{"title":_vm.name,"type":"Separation Rule","store":"separationRule"},on:{"save-success":function (ref) {
                var id = ref.id;

                return _vm.$router.push({
          name: 'separation-rules.show',
          params: { separationRuleId: id }
        });
}}}),_c('separation-rule')],1)}
var staticRenderFns = []

export { render, staticRenderFns }