<template>
  <v-layout>
    <v-flex :xs12="!internalSidebar" :xs8="internalSidebar">
      <v-toolbar color="primary" dark dense>
        <v-icon v-if="icon">
          {{ icon }}
        </v-icon>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <slot name="toolbar-additional-search" />
        <v-text-field
          v-if="internalSearchable"
          v-model="filters.search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        />
        <slot name="toolbar-actions" />
        <v-btn v-if="internalSidebarToggleable" icon @click="toggleSidebar">
          <v-icon>{{ sidebarIcon }}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <slot name="content" />
      </v-card>
    </v-flex>
    <v-flex v-if="internalSidebar" xs4>
      <slot name="sidebar">
        <v-card v-if="filters">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filter</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <slot name="filters" />
          </v-card-text>
          <v-card-actions>
            <v-btn flat color="orange" @click.native="$emit('filter-search')">
              Search
            </v-btn>
            <v-btn flat color="orange" @click.native="$emit('filter-clear')">
              Clear
            </v-btn>
          </v-card-actions>
        </v-card>
      </slot>
    </v-flex>

    <v-dialog
      v-model="internalDialog"
      :content-class="dialogContentClass"
      hide-overlay
    >
      <slot name="dialog" />
    </v-dialog>

    <entity-overview-actions :actions="actions" :focused="focused" />
  </v-layout>
</template>
<script>
import { isNil, isUndefined } from "lodash";
import EntityOverviewActions from "./OverviewActions";

export default {
  name: "generic-overview",
  components: {
    EntityOverviewActions
  },
  props: {
    route: {
      type: String,
      required: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      required: false
    },
    joins: {
      type: Array,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: true
    },
    sidebar: {
      type: Boolean,
      default: false
    },
    sidebarToggleable: {
      type: Boolean,
      default: false
    },
    sidebarIcon: {
      type: String,
      default: "filter_list"
    },
    dialog: {
      type: Boolean,
      default: false
    },
    dialogContentClass: {
      type: String,
      default: "details-dialog"
    }
  },
  data() {
    return {
      isSidebarOpen: false,
      fab: false
    };
  },
  computed: {
    internalSearchable() {
      return (
        this.searchable &&
        !isNil(this.filters) &&
        !isUndefined(this.filters.search)
      );
    },
    internalDialog: {
      get() {
        return this.dialog;
      },
      set() {
        if (isNil(this.route)) {
          console.error("No route specified for generic overview!");
        }
        this.$router.push({ name: this.route });
      }
    },
    internalSidebar() {
      return this.sidebar || this.isSidebarOpen;
    },
    internalSidebarToggleable() {
      return this.sidebarToggleable || this.filters;
    }
  },
  methods: {
    toggleSidebar({ open }) {
      this.isSidebarOpen = isNil(open) ? !this.isSidebarOpen : open;
    }
  }
};
</script>
