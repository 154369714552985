<template>
  <v-card color="white">
    <crud-header :title="name" type="Separation Rule" store="separationRule">
    </crud-header>
    <separation-rule :match-data="triggerGroupMatchData" />
  </v-card>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import SeparationRule from "@/components/separation-rule/SeparationRule";
import CrudHeader from "@/components/generic/crud/CrudHeader";
import { mapDetailViewLifecycleMethods } from "@/views/helpers";
import separationRuleModule from "@/store/modules/separation-rule";

const { mapGetters } = createNamespacedHelpers("separationRule");

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "separationRule",
  separationRuleModule
);

export default {
  name: "separation-rule-view",
  components: {
    CrudHeader,
    SeparationRule
  },
  computed: {
    ...mapGetters(["id", "name"]),
    triggerGroupMatchData() {
      return this.$store.state.separationRule.ruleTriggerGroups
        .ruleTriggerGroupMatchData;
    }
  },
  data: () => ({}),
  ...detailViewLifecycleMethods
};
</script>
