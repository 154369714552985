<script type="text/babel">
import { debounce, isNil } from "lodash";

export default {
  name: "b-select-mixin",
  props: {
    items: {
      type: Array,
      required: false
    },
    store: {
      type: String,
      required: false
    },
    eager: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      required: false
    },
    deletableChips: {
      type: Boolean,
      required: false
    },
    itemText: {
      type: String,
      required: false
    },
    returnObject: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.init();
  },
  computed: {
    internalItems() {
      // If this is not async, just return the items property.
      if (!this.isAsync) {
        return this.items;
      }
      const items = this.asyncItems;

      // If items does not yet contain the value, prepend the value to items. This is a
      // workaround for Vuetify's inability to show a selection that is not in the items
      // property.
      return this.preloadValueToItems(items);
    },
    isAsync() {
      return !isNil(this.store);
    },
    isAsyncAndRegistered() {
      return this.isAsync && !isNil(this.$store.state[this.store]);
    },
    asyncItems() {
      return this.isAsyncAndRegistered
        ? this.$store.getters[`${this.store}/items`]
        : [];
    },
    isDefaultLoaded() {
      return this.isAsyncAndRegistered
        ? this.$store.state[this.store].isDefaultLoaded
        : false;
    },
    isLoading() {
      return this.isAsyncAndRegistered
        ? this.$store.state[this.store].isLoading
        : false;
    }
  },
  methods: {
    throttledDispatch: debounce(($store, action, payload = undefined) => {
      $store.dispatch(action, payload);
    }, 500),
    onClick() {
      if (this.isAsyncAndRegistered && !this.isDefaultLoaded) {
        this.throttledDispatch(this.$store, `${this.store}/init`);
      }
    },
    preloadValueToItems(items) {
      // If value is null or undefined, exit early.
      if (isNil(this.value)) {
        return items;
      }
      // Otherwise if this is not multiple, adding value to items and exit early.
      if (this.multiple) {
        return [...items, ...this.value];
      }
      // Otherwise push the value unto items.
      return [...items, this.value];
    },
    async init() {
      if (this.isAsync) {
        // If this is an async select, register the store if it has not been. We do this
        // in an async function so we can lazy load the vuex store modules.
        await this.initStore();
        if (this.eager) {
          // If the eager property is provided, eager load the data instead of waiting for
          // first interaction.
          await this.initData();
        }
      }
    },
    async initStore() {
      if (isNil(this.store)) {
        throw Error("No store name defined.");
      }
      if (isNil(this.$store.state[this.store])) {
        const module = await import(`@/store/modules/${this.store}`);
        if (isNil(this.$store.state[this.store])) {
          // Double check here, because of the race condition introduced by the async import.
          this.$store.registerModule(this.store, module.default);
          console.debug(`[VUEX] Registering ${this.store} module.`);
        }
      }
    },
    async initData() {
      if (this.isAsyncAndRegistered && !this.isDefaultLoaded) {
        this.throttledDispatch(this.$store, `${this.store}/init`);
      }
    }
  }
};
</script>
