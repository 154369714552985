<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "b-validation-mixin",
  inject: ["$validator"],
  props: {
    label: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    disableValidation: {
      type: Boolean,
      default: false
    },
    validateAs: {
      type: String,
      required: false
    },
    validateName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    validationRules() {
      // If the disableValidation property is provided, return undefined.
      if (this.disableValidation) {
        return undefined;
      }
      const rules = {};
      if (this.required) {
        rules.required = true;
      }
      return rules;
    },
    internalValidationLabel() {
      // If provided, return the validateAs property.
      if (!isNil(this.validateAs)) {
        return this.validateAs;
      }
      // Otherwise, if provided, return the label property.
      if (!isNil(this.label)) {
        return this.label;
      }
      // Otherwise, return the internalName property.
      return this.internalName;
    },
    internalValidationName() {
      // If provided, return the validateAs property.
      if (!isNil(this.validateName)) {
        return this.validateName;
      }
      // Otherwise, return the internalName property.
      return this.internalName;
    },
    internalErrorMessages() {
      if (!this.errors.has(this.internalValidationName)) {
        return this.errorMessages;
      }
      return this.errorMessages.concat(
        this.errors.collect(this.internalValidationName)
      );
    }
  }
};
</script>
