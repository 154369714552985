<script type="text/babel">
import BModelMixin from "@/components/generic/BModelMixin";

export default {
  name: "v-select-wrapper-mixin",
  mixins: [BModelMixin],
  props: {
    value: {
      type: [Object, Array],
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      required: false
    },
    hintOnFocus: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  inject: ["$validator"],
  computed: {
    selectProperties() {
      return {
        multiple: this.$props.multiple,
        hint: this.$props.hint,
        hintOnFocus: this.$props.hintOnFocus,
        errorMessages: this.$props.errorMessages,
        returnObject: true
      };
    }
  }
};
</script>
