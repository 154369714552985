<template>
  <v-fab-transition>
    <v-btn
      v-if="internalActions.length === 1"
      v-show="!hide"
      fab
      bottom
      right
      dark
      fixed
      :color="internalActions[0].color"
      :to="internalActions[0].to"
      :href="internalActions[0].href"
    >
      <v-icon>{{ internalActions[0].icon }}</v-icon>
    </v-btn>
    <v-speed-dial
      v-else-if="internalActions.length > 1"
      v-show="!hide"
      :top="false"
      :bottom="true"
      :right="true"
      :left="false"
      fixed
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <v-btn slot="activator" v-model="fab" color="blue darken-2" dark fab>
        <v-icon>more_vert</v-icon>
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn
        v-for="(action, index) in internalActions"
        :key="index"
        fab
        dark
        small
        :color="action.color"
        :to="action.to"
        :href="action.href"
        @click.native="action.clickFn"
      >
        <v-icon>{{ action.icon }}</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-fab-transition>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "entity-overview-actions",
  props: {
    actions: {
      type: Array,
      default: () => []
    },
    hide: {
      type: Boolean,
      false: true
    }
  },
  data() {
    return {
      fab: false
    };
  },
  computed: {
    ...mapGetters(["hasRolesOrPermissions"]),
    internalActions() {
      return this.actions.filter(action =>
        this.hasRolesOrPermissions(action.rolesOrPermissions)
      );
    }
  }
};
</script>
