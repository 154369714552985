<template>
  <div>
    <span
      class="title text-uppercase secondary--text"
      :class="{
        'secondary--text': !hasMatchData,
        'success--text': hasMatchData && isMatched,
        'warning--text': hasMatchData && !isMatched
      }"
    >
      Trigger Groups
    </span>
    <div class="d-flex flex-column">
      <submission-trigger-group
        v-for="({ triggerGroup, matchResult },
        index) in triggerGroupsWithMatchResults"
        :key="index"
        :class="{ 'flex-1': true, 'mt-3': index >= 0 }"
        :store="store"
        :trigger-group="triggerGroup"
        :trigger-group-index="index"
        :match-data="matchResult"
        :entity-name="entityName"
      />
      <div
        class="flex-1"
        v-if="errors.has('triggerGroups') && triggerGroups.length === 0"
      >
        <div
          class="error--border border--width-2 border--dashed text-xs-center"
        >
          <span class="title error--text">
            {{ errors.first("triggerGroups") }}
          </span>
        </div>
      </div>
      <div class="flex-1 mt-3 text-xs-center">
        <v-btn flat color="primary" @click="createInNewGroup">
          <v-icon color="pink">
            far fa-fw fa-plus
          </v-icon>
          Add trigger to new group
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script type="text/babel">
import SubmissionTriggerGroup from "./SubmissionTriggerGroup.vue";
import { ADD_RULE_TRIGGER_TO_NEW_GROUP } from "@/store/modules/rule-trigger-groups/mutation-types";

export default {
  name: "submission-triggers",
  components: { SubmissionTriggerGroup },
  inject: ["$validator"],
  props: {
    store: {
      type: String,
      required: true
    },
    matchData: {
      type: Object,
      default: undefined
    },
    entityName: {
      type: String,
      required: true
    }
  },
  computed: {
    hasMatchData() {
      return this.matchData !== undefined;
    },
    isMatched() {
      return this.hasMatchData && this.matchData.isMatched;
    },
    /**
     * Returns the triggerGroups model from the store.
     *
     * @returns {{}}
     */
    triggerGroups() {
      return this.$store.state[this.entityName].ruleTriggerGroups.model;
    },
    /**
     * Maps triggerGroups into an array of objects containing both a triggerGroup and
     * it's matchResult.
     *
     * @returns {[{triggerGroup: {}, matchResult: {}}]}
     */
    triggerGroupsWithMatchResults() {
      return this.triggerGroups.map(triggerGroup => ({
        triggerGroup,
        matchResult: this.matchData?.triggerGroupMatches.find(
          triggerGroupMatch => triggerGroupMatch.id === triggerGroup.id
        )
      }));
    }
  },
  methods: {
    createInNewGroup: function() {
      this.$store.commit(
        `${this.entityName}/ruleTriggerGroups/${ADD_RULE_TRIGGER_TO_NEW_GROUP}`
      );
    }
  }
};
</script>
