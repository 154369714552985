<script type="text/babel">
export default {
  name: "v-model-mixin",
  props: {
    value: {
      type: [Object, String, Number, Array],
      required: false
    }
  },
  computed: {
    internalValue: {
      /**
       * Returns the value property.
       *
       * @returns {boolean}
       */
      get() {
        return this.value;
      },
      /**
       * Emits the new value as "input", making it update the parent's configured v-model property.
       */
      set(newValue) {
        this.$emit("input", Object.is(newValue, undefined) ? null : newValue);
      }
    }
  }
};
</script>
