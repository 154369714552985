<template>
  <v-text-field
    v-model="internalValue"
    v-validate="{ max: maxLength, ...validationRules }"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="internalValidationName"
    :error-messages="internalErrorMessages"
    :label="label"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :loading="loading"
    :clearable="clearable"
    :counter="maxLength"
    :browser-autocomplete="autocomplete"
    :prepend-icon="prependIcon"
  />
</template>
<script type="text/babel">
import BInputMixin from "@/components/generic/BInputMixin";
import BValidationMixin from "@/components/generic/BValidationMixin";
import BModelMixin from "@/components/generic/BModelMixin";

export default {
  name: "b-text-field",
  props: {
    maxLength: {
      type: Number,
      default: 255
    }
  },
  mixins: [BModelMixin, BInputMixin, BValidationMixin]
};
</script>
