<script type="text/babel">
import BAutocomplete from "@/components/generic/BAutocomplete";
import BSelectWrapperMixin from "@/components/generic/BSelectWrapperMixin";

export default {
  name: "b-autocomplete-country",
  mixins: [BSelectWrapperMixin],
  components: { BAutocomplete }
};
</script>

<template>
  <b-autocomplete
    v-model="internalValue"
    v-bind="selectProperties"
    store="b-country-select"
    :label="multiple ? 'Countries' : 'Country'"
    item-text="name"
    autocomplete="different-countries"
  />
</template>
