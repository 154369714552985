<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Separation Rule"
      store="separationRule"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'separation-rules.show',
            params: { separationRuleId: id }
          })
      "
    />
    <separation-rule />
  </v-card>
</template>

<script type="text/babel">
import separationRuleModule from "@/store/modules/separation-rule";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
import { mapCreateViewLifecycleMethods } from "../helpers";
import SeparationRule from "@/components/separation-rule/SeparationRule";

const { mapGetters } = createNamespacedHelpers("separationRule");

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "separationRule",
  separationRuleModule
);

export default {
  name: "separation-rule-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    SeparationRule,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
