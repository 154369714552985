<script>
import {
  SET_RULE_TRIGGER_COUNTRIES,
  SET_RULE_TRIGGER_IS_CASE_SENSITIVE,
  SET_RULE_TRIGGER_IS_COUNTRIES_INVERTED,
  SET_RULE_TRIGGER_IS_INVERTED,
  SET_RULE_TRIGGER_IS_LANGUAGES_INVERTED,
  SET_RULE_TRIGGER_IS_SUBJECTS_TYPE,
  SET_RULE_TRIGGER_LANGUAGES,
  SET_RULE_TRIGGER_SPECIFIER,
  SET_RULE_TRIGGER_SUBJECTS,
  SET_RULE_TRIGGER_TARGETS,
  SET_RULE_TRIGGER_TYPE,
  SET_RULE_TRIGGER_VALUE
} from "@/store/modules/rule-trigger-groups/mutation-types";

export default {
  name: "submission-trigger-mixin",
  computed: {
    countries: {
      get() {
        return this.trigger.countries;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_COUNTRIES}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            countries: newVal
          }
        );
      }
    },
    languages: {
      get() {
        return this.trigger.languages;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_LANGUAGES}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            languages: newVal
          }
        );
      }
    },
    subjects: {
      get() {
        return this.trigger.subjects;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_SUBJECTS}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            subjects: newVal
          }
        );
      }
    },
    isCountriesInverted: {
      get() {
        return this.trigger.isCountriesInverted;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_IS_COUNTRIES_INVERTED}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            isCountriesInverted: newVal
          }
        );
      }
    },
    isLanguagesInverted: {
      get() {
        return this.trigger.isLanguagesInverted;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_IS_LANGUAGES_INVERTED}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            isLanguagesInverted: newVal
          }
        );
      }
    },
    subjectsType: {
      get() {
        return this.trigger.subjectsType;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_IS_SUBJECTS_TYPE}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            type: newVal
          }
        );
      }
    },
    isInverted: {
      get() {
        return this.trigger.isInverted;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_IS_INVERTED}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            isInverted: newVal
          }
        );
      }
    },
    isCaseSensitive: {
      get() {
        return this.trigger.isCaseSensitive;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_IS_CASE_SENSITIVE}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            isCaseSensitive: newVal
          }
        );
      }
    },
    specifier: {
      get() {
        return this.trigger.specifier;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_SPECIFIER}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            targets: newVal
          }
        );
      }
    },
    targets: {
      get() {
        return this.trigger.targets;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_TARGETS}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            targets: newVal
          }
        );
      }
    },
    type: {
      get() {
        return this.trigger.type;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_TYPE}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            type: newVal
          }
        );
      }
    },
    value: {
      get() {
        return this.trigger.value;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_VALUE}`,
          {
            triggerGroup: this.triggerGroup,
            trigger: this.trigger,
            value: newVal
          }
        );
      }
    }
  }
};
</script>
