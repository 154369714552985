var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex flex-column pa-3': true,
    'white-smoke border--width-2 border--dashed': true,
    'secondary--border': !_vm.hasMatchData,
    'warning--border': _vm.hasMatchData && !_vm.matchData.isMatched,
    'success--border': _vm.hasMatchData && _vm.matchData.isMatched
  }},[_c('b-select',{attrs:{"items":_vm.triggerTargets,"label":"Targets","multiple":"","validate-name":(_vm.validationPrefix + ".targets")},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"mr-1",class:{
          'success--text':
            _vm.hasMatchData && _vm.matchingTargets.includes(item.value),
          'warning--text':
            _vm.hasMatchData && !_vm.matchingTargets.includes(item.value)
        }},[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.targets),callback:function ($$v) {_vm.targets=$$v},expression:"targets"}}),_vm._l((_vm.triggersAndMatchResults),function(ref,index){
        var trigger = ref.trigger;
        var matchResults = ref.matchResults;
return _c('submission-trigger',{key:index,class:{ 'flex-1': true, 'mt-3': index !== 0 },attrs:{"store":_vm.store,"trigger-group-index":_vm.triggerGroupIndex,"trigger-index":index,"trigger-group":_vm.triggerGroup,"trigger":trigger,"match-results":matchResults,"entity-name":_vm.entityName},on:{"remove":_vm.remove}})}),_c('div',{staticClass:"flex-1 mt-3 text-xs-center"},[_c('v-btn',{attrs:{"flat":"","color":"primary"},nativeOn:{"click":function($event){return _vm.createInExistingGroup($event)}}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v(" far fa-fw fa-plus ")]),_vm._v(" Add trigger to this group ")],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }