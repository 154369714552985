<template>
  <div
    :class="{
      'd-flex flex-column pa-3': true,
      'white-smoke border--width-2 border--dashed': true,
      'secondary--border': !hasMatchData,
      'warning--border': hasMatchData && !matchData.isMatched,
      'success--border': hasMatchData && matchData.isMatched
    }"
  >
    <b-select
      v-model="targets"
      :items="triggerTargets"
      label="Targets"
      multiple
      :validate-name="`${validationPrefix}.targets`"
    >
      <template v-slot:selection="{ item }">
        <span
          class="mr-1"
          :class="{
            'success--text':
              hasMatchData && matchingTargets.includes(item.value),
            'warning--text':
              hasMatchData && !matchingTargets.includes(item.value)
          }"
          >{{ item.text }}</span
        >
      </template>
    </b-select>
    <submission-trigger
      v-for="({ trigger, matchResults }, index) in triggersAndMatchResults"
      :key="index"
      :class="{ 'flex-1': true, 'mt-3': index !== 0 }"
      :store="store"
      :trigger-group-index="triggerGroupIndex"
      :trigger-index="index"
      :trigger-group="triggerGroup"
      :trigger="trigger"
      :match-results="matchResults"
      :entity-name="entityName"
      @remove="remove"
    />
    <div class="flex-1 mt-3 text-xs-center">
      <v-btn flat color="primary" @click.native="createInExistingGroup">
        <v-icon color="pink">
          far fa-fw fa-plus
        </v-icon>
        Add trigger to this group
      </v-btn>
    </div>
  </div>
</template>
<script type="text/babel">
import SubmissionTrigger from "./SubmissionTrigger.vue";
import { REMOVE_RULE_TRIGGER } from "@/store/modules/rule-trigger-groups/action-types";
import {
  ADD_RULE_TRIGGER_TO_GROUP,
  SET_RULE_TRIGGER_GROUP_TARGETS
} from "@/store/modules/rule-trigger-groups/mutation-types";
import BSelect from "@/components/generic/BSelect";

export default {
  name: "submission-trigger-group",
  components: { BSelect, SubmissionTrigger },
  inject: ["$validator"],
  props: {
    store: {
      type: String,
      required: true
    },
    triggerGroup: {
      type: Object,
      required: true
    },
    triggerGroupIndex: {
      type: Number,
      required: true
    },
    matchData: {
      type: Object,
      default: undefined
    },
    entityName: {
      type: String,
      required: true
    }
  },
  computed: {
    hasMatchData() {
      return this.matchData !== undefined;
    },
    matchingTargets() {
      // Filter and map the triggerGroupTargetValueMatches to get an array of matching
      // targets or undefined if matchData is undefined.
      const matchingTargets = this.matchData?.triggerGroupTargetValueMatches
        .filter(({ isMatched }) => isMatched)
        .map(match => match.target);
      // Use the spread syntax and a Set to remove duplicates and turn undefined into an
      // empty array.
      return [...new Set(matchingTargets)];
    },
    triggersAndMatchResults() {
      return this.triggerGroup.triggers.map(trigger => ({
        trigger,
        matchResults: this.matchData?.triggerGroupTargetValueMatches.map(
          ({ target, value, triggerMatches }) => ({
            target,
            value,
            ...triggerMatches.find(({ id }) => id === trigger.id)
          })
        )
      }));
    },
    validationPrefix() {
      return `triggerGroups.${this.triggerGroupIndex}`;
    },
    triggerTargets() {
      return Object.entries(
        window.config.enums.submission_triggers.targets
      ).map(o => ({
        text: o[1],
        value: o[0]
      }));
    },
    targets: {
      get() {
        return this.triggerGroup.targets;
      },
      set(newVal) {
        return this.$store.commit(
          `${this.entityName}/ruleTriggerGroups/${SET_RULE_TRIGGER_GROUP_TARGETS}`,
          {
            triggerGroup: this.triggerGroup,
            targets: newVal
          }
        );
      }
    }
  },
  methods: {
    createInExistingGroup() {
      this.$store.commit(
        `${this.entityName}/ruleTriggerGroups/${ADD_RULE_TRIGGER_TO_GROUP}`,
        this.triggerGroup
      );
    },
    remove({ trigger }) {
      this.$store.dispatch(
        `${this.entityName}/ruleTriggerGroups/${REMOVE_RULE_TRIGGER}`,
        { triggerGroup: this.triggerGroup, trigger }
      );
    }
  }
};
</script>
