<template>
  <v-switch
    v-model="internalValue"
    v-validate="validationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="internalValidationName"
    :error-messages="internalErrorMessages"
    :label="label"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :loading="loading"
    :color="color"
  />
</template>
<script type="text/babel">
import BInputMixin from "@/components/generic/BInputMixin";
import BValidationMixin from "@/components/generic/BValidationMixin";
import BModelMixin from "@/components/generic/BModelMixin";

export default {
  name: "b-switch",
  props: {
    value: {
      type: Boolean,
      required: false
    },
    color: {
      type: String,
      default: "primary"
    }
  },
  mixins: [BModelMixin, BInputMixin, BValidationMixin]
};
</script>
