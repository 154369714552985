import {
  ADD_RULE_TRIGGER_QUESTION,
  ADD_RULE_TRIGGER_TO_GROUP,
  ADD_RULE_TRIGGER_TO_NEW_GROUP,
  REMOVE_RULE_TRIGGER_FROM_GROUP,
  REMOVE_RULE_TRIGGER_GROUP,
  REMOVE_RULE_TRIGGER_QUESTION,
  SET_RULE_TRIGGER_COUNTRIES,
  SET_RULE_TRIGGER_GROUP_MATCH_DATA,
  SET_RULE_TRIGGER_GROUP_TARGETS,
  SET_RULE_TRIGGER_IS_CASE_SENSITIVE,
  SET_RULE_TRIGGER_IS_COUNTRIES_INVERTED,
  SET_RULE_TRIGGER_IS_INVERTED,
  SET_RULE_TRIGGER_IS_LANGUAGES_INVERTED,
  SET_RULE_TRIGGER_IS_SUBJECTS_TYPE,
  SET_RULE_TRIGGER_LANGUAGES,
  SET_RULE_TRIGGER_QUESTION_OPTIONS,
  SET_RULE_TRIGGER_QUESTION_TYPE,
  SET_RULE_TRIGGER_SPECIFIER,
  SET_RULE_TRIGGER_SUBJECTS,
  SET_RULE_TRIGGER_TARGETS,
  SET_RULE_TRIGGER_TYPE,
  SET_RULE_TRIGGER_VALUE
} from "@/store/modules/rule-trigger-groups/mutation-types";
import crudMutations from "@/store/templates/b-crud/mutations";

export default {
  ...crudMutations,
  [ADD_RULE_TRIGGER_TO_GROUP](state, triggerGroup) {
    triggerGroup.triggers.push(submissionTriggerTemplate());
  },
  [ADD_RULE_TRIGGER_TO_NEW_GROUP](state) {
    state.model.push({
      id: null,
      targets: [],
      triggers: []
    });
  },
  [REMOVE_RULE_TRIGGER_FROM_GROUP](state, { triggerGroup, trigger }) {
    const index = triggerGroup.triggers.indexOf(trigger);
    triggerGroup.triggers.splice(index, 1);
  },
  [REMOVE_RULE_TRIGGER_GROUP](state, triggerGroup) {
    const index = state.model.indexOf(triggerGroup);
    state.model.splice(index, 1);
  },

  [SET_RULE_TRIGGER_GROUP_TARGETS](state, { triggerGroup, targets }) {
    triggerGroup.targets = targets;
  },

  [SET_RULE_TRIGGER_TYPE](state, { trigger, type }) {
    trigger.type = type;
    trigger.value = type === "CHAR_COUNT" ? null : [];
  },
  [SET_RULE_TRIGGER_TARGETS](state, { trigger, targets }) {
    trigger.targets = targets;
  },
  [SET_RULE_TRIGGER_VALUE](state, { trigger, value }) {
    trigger.value = value;
  },
  [SET_RULE_TRIGGER_SPECIFIER](state, { trigger, specifier }) {
    trigger.specifier = specifier;
  },
  [SET_RULE_TRIGGER_COUNTRIES](state, { trigger, countries }) {
    trigger.countries = countries;
  },
  [SET_RULE_TRIGGER_LANGUAGES](state, { trigger, languages }) {
    trigger.languages = languages;
  },
  [SET_RULE_TRIGGER_SUBJECTS](state, { trigger, subjects }) {
    trigger.subjects = subjects;
  },
  [SET_RULE_TRIGGER_IS_COUNTRIES_INVERTED](
    state,
    { trigger, isCountriesInverted }
  ) {
    trigger.isCountriesInverted = isCountriesInverted;
  },
  [SET_RULE_TRIGGER_IS_LANGUAGES_INVERTED](
    state,
    { trigger, isLanguagesInverted }
  ) {
    trigger.isLanguagesInverted = isLanguagesInverted;
  },
  [SET_RULE_TRIGGER_IS_SUBJECTS_TYPE](state, { trigger, type }) {
    trigger.subjectsType = type;
  },
  [SET_RULE_TRIGGER_IS_INVERTED](state, { trigger, isInverted }) {
    trigger.isInverted = isInverted;
  },
  [SET_RULE_TRIGGER_IS_CASE_SENSITIVE](state, { trigger, isCaseSensitive }) {
    trigger.isCaseSensitive = isCaseSensitive;
  },
  [SET_RULE_TRIGGER_GROUP_MATCH_DATA](state, triggerGroupMatchData) {
    state.ruleTriggerGroupMatchData = triggerGroupMatchData;
  },
  [SET_RULE_TRIGGER_QUESTION_TYPE](state, { question, type }) {
    const triggerQuestion = getRuleTriggerQuestionFromModel(state, question);
    triggerQuestion.type = type;
  },
  [SET_RULE_TRIGGER_QUESTION_OPTIONS](state, { question, options }) {
    question.options = options;
  },
  [REMOVE_RULE_TRIGGER_QUESTION](state, { ruleTrigger, question }) {
    const index = ruleTrigger.triggerQuestions.indexOf(question);
    ruleTrigger.triggerQuestions.splice(index, 1);
  },
  [ADD_RULE_TRIGGER_QUESTION](state, { ruleTrigger, question }) {
    ruleTrigger.triggerQuestions.push({
      id: null,
      question,
      type: "ANY",
      options: []
    });
  }
};

function getTriggersFromModel(state) {
  return (
    state.model
      // Reduce model trigger groups down to an array of triggers.
      .reduce(
        (accumulator, triggerGroup) => accumulator.concat(triggerGroup),
        []
      )
  );
}

function getRuleTriggerQuestionFromModel(state, ruleTriggerQuestion) {
  const triggerQuestions = getTriggersFromModel(state)
    // Reduce triggers down to an array of triggerQuestions.
    .reduce(
      (accumulator, trigger) => accumulator.concat(trigger.triggerQuestions),
      []
    );
  return triggerQuestions.find(q => q === ruleTriggerQuestion);
}

const submissionTriggerTemplate = () => ({
  parent: null,
  type: null,
  specifier: null,
  value: null,
  isInverted: false,
  isCaseSensitive: true,
  subjects: [],
  subjectsType: "any",
  countries: [],
  isCountriesInverted: false,
  languages: [],
  isLanguagesInverted: false,
  triggerQuestions: []
});
