var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"title text-uppercase secondary--text",class:{
      'secondary--text': !_vm.hasMatchData,
      'success--text': _vm.hasMatchData && _vm.isMatched,
      'warning--text': _vm.hasMatchData && !_vm.isMatched
    }},[_vm._v(" Trigger Groups ")]),_c('div',{staticClass:"d-flex flex-column"},[_vm._l((_vm.triggerGroupsWithMatchResults),function(ref,index){
    var triggerGroup = ref.triggerGroup;
    var matchResult = ref.matchResult;
return _c('submission-trigger-group',{key:index,class:{ 'flex-1': true, 'mt-3': index >= 0 },attrs:{"store":_vm.store,"trigger-group":triggerGroup,"trigger-group-index":index,"match-data":matchResult,"entity-name":_vm.entityName}})}),(_vm.errors.has('triggerGroups') && _vm.triggerGroups.length === 0)?_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"error--border border--width-2 border--dashed text-xs-center"},[_c('span',{staticClass:"title error--text"},[_vm._v(" "+_vm._s(_vm.errors.first("triggerGroups"))+" ")])])]):_vm._e(),_c('div',{staticClass:"flex-1 mt-3 text-xs-center"},[_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":_vm.createInNewGroup}},[_c('v-icon',{attrs:{"color":"pink"}},[_vm._v(" far fa-fw fa-plus ")]),_vm._v(" Add trigger to new group ")],1)],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }