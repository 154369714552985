<script type="text/babel">
import { AUTH_USER_LOCALE } from "@/store/modules/locales/getter-types";

export default {
  name: "auth-user-locale-mixin",
  computed: {
    authUserLocale() {
      return this.$store.getters[`locales/${AUTH_USER_LOCALE}`];
    },
    authUserLocaleCode() {
      return this.authUserLocale.code;
    }
  }
};
</script>
