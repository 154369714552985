<template>
  <v-select
    v-model="internalValue"
    v-validate="validationRules"
    :name="internalName"
    :data-vv-name="internalValidationName"
    :data-vv-as="internalValidationLabel"
    :error-messages="internalErrorMessages"
    :label="label"
    :multiple="multiple"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :loading="isLoading"
    :chips="chips || deletableChips"
    :deletable-chips="deletableChips"
    :items="internalItems"
    :item-text="itemText"
    :clearable="!required || clearable"
    :browser-autocomplete="autocomplete"
    :prepend-icon="prependIcon"
    :return-object="returnObject"
    @click="onClick"
    class="pb-2"
  >
    <template v-slot:selection="scope" v-if="hasSelectionSlot">
      <slot name="selection" v-bind="scope" />
    </template>
    <template v-slot:item="scope" v-if="hasItemSlot">
      <slot name="item" v-bind="scope" />
    </template>
  </v-select>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import BInputMixin from "@/components/generic/BInputMixin";
import BSelectMixin from "@/components/generic/BSelectMixin";
import BValidationMixin from "@/components/generic/BValidationMixin";
import BModelMixin from "@/components/generic/BModelMixin";

export default {
  name: "b-select",
  mixins: [BModelMixin, BInputMixin, BSelectMixin, BValidationMixin],
  computed: {
    /**
     * Returns true if the selection slot has been provided.
     *
     * @returns {boolean}
     */
    hasSelectionSlot() {
      return !isNil(this.$scopedSlots.selection);
    },
    /**
     * Returns true if the item slot has been provided.
     *
     * @returns {boolean}
     */
    hasItemSlot() {
      return !isNil(this.$scopedSlots.item);
    }
  }
};
</script>
