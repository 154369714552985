<script type="text/babel">
import { isNil, snakeCase } from "lodash";

export default {
  name: "b-input-mixin",
  props: {
    label: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    hint: {
      type: String,
      required: false
    },
    hintOnFocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    prependIcon: {
      type: String,
      required: false
    }
  },
  computed: {
    internalName() {
      if (!isNil(this.name)) {
        return this.name;
      }
      if (!isNil(this.label)) {
        return snakeCase(this.label);
      }
      return undefined;
    },
    isLoading() {
      return this.loading;
    },
    isDisabled() {
      return this.disabled || this.isLoading;
    },
    isReadOnly() {
      return this.disabled || this.isLoading;
    }
  }
};
</script>
