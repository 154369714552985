<script type="text/babel">
import BAutocomplete from "@/components/generic/BAutocomplete";
import BSelectWrapperMixin from "@/components/generic/BSelectWrapperMixin";

export default {
  name: "b-autocomplete-language",
  mixins: [BSelectWrapperMixin],
  components: { BAutocomplete }
};
</script>

<template>
  <b-autocomplete
    v-model="internalValue"
    v-bind="selectProperties"
    store="b-language-select"
    :label="multiple ? 'Languages' : 'Language'"
    item-text="name"
    autocomplete="different-languages"
  />
</template>
