<template>
  <generic-overview
    :route="route"
    :actions="actions"
    :filters="filters"
    searchable
    title="Separation Rules"
  >
    <table-data-provider
      slot="content"
      ref="table"
      :url="url"
      :includes="includes"
      :pagination="pagination"
      :filters="filters"
      :entity-name="entityName"
      :schema="schema"
    >
      <v-data-table
        slot-scope="props"
        :headers="headers"
        :items="props.items"
        :pagination.sync="pagination"
        :total-items="props.totalItems"
        :rows-per-page-items="[20, 50, 100]"
        disable-initial-sort
        disable-page-reset
        :loading="props.isLoading"
        class="elevation-1"
      >
        <separation-rule-table-row
          slot="items"
          slot-scope="{ item }"
          :separation-rule-id="item.id"
        />
      </v-data-table>
    </table-data-provider>
  </generic-overview>
</template>
<script type="text/babel">
import { separationRule as schema } from "@/store/schema";
import TableDataProvider from "@/components/legacy/generic/data/TableDataProvider";
import GenericOverview from "@/components/generic/overview/Overview";
import SeparationRuleTableRow from "@/components/separation-rule/SeparationRuleTableRow";

export default {
  name: "separation-rule-overview",
  components: {
    SeparationRuleTableRow,
    TableDataProvider,
    GenericOverview
  },
  data() {
    return {
      route: "separation-rules",
      url: "api/v1/separation-rules",
      actions: [
        { icon: "add", color: "pink", to: { name: "separation-rules.create" } }
      ],
      pagination: {},
      filters: {
        search: null
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Groups", value: "groups", sortable: false },
        { text: "Active", align: "right", value: "is_active", width: "100px" },
        {
          text: "Actions",
          align: "right",
          value: "name",
          sortable: false,
          width: "100px"
        }
      ],
      includes: ["groups.subjects"],
      entityName: "separationRule"
    };
  },
  computed: {
    schema() {
      return schema;
    }
  }
};
</script>
