<template>
  <div class="d-flex flex-column">
    <submission-trigger-question
      v-for="(ruleTriggerQuestion, index) in ruleTriggerQuestions"
      :key="index"
      class="flex-1"
      :store="store"
      :rule-trigger="ruleTrigger"
      :rule-trigger-question="ruleTriggerQuestion"
      :validation-prefix="`${validationPrefix}.${index}`"
    />
    <div class="flex-1 d-flex">
      <b-autocomplete-question
        v-model="questionToAdd"
        class="flex-11"
        :error-messages="
          isQuestionAddable
            ? ['This question is already configured.']
            : undefined
        "
      />
      <div class="flex-1 text-xs-center">
        <v-btn @click="add" :disabled="isQuestionAddable">Add</v-btn>
      </div>
    </div>
  </div>
</template>
<script type="text/babel">
import SubmissionTriggerQuestion from "@/components/submission-trigger/SubmissionTriggerQuestion";
import { ADD_RULE_TRIGGER_QUESTION } from "@/store/modules/rule-trigger-groups/mutation-types";
import BAutocompleteQuestion from "@/components/question/BAutocompleteQuestion";
import { isNil } from "lodash";

export default {
  name: "submission-trigger-questions",
  components: {
    BAutocompleteQuestion,
    SubmissionTriggerQuestion
  },
  inject: ["$validator"],
  props: {
    store: {
      type: String,
      required: true
    },
    ruleTrigger: {
      type: Object,
      required: true
    },
    ruleTriggerQuestions: {
      type: Array,
      required: true
    },
    validationPrefix: {
      type: String,
      required: true
    }
  },
  data: () => ({
    questionToAdd: null
  }),
  computed: {
    isQuestionAddable() {
      if (isNil(this.questionToAdd)) {
        return false;
      }
      return (
        this.ruleTriggerQuestions.filter(triggerQuestion => {
          return triggerQuestion.question.id === this.questionToAdd.id;
        }).length > 0
      );
    }
  },
  methods: {
    add() {
      this.$store.commit(
        `${this.store}/ruleTriggerGroups/${ADD_RULE_TRIGGER_QUESTION}`,
        {
          ruleTrigger: this.ruleTrigger,
          question: this.questionToAdd
        }
      );
      this.questionToAdd = null;
    }
  }
};
</script>
