<script type="text/babel">
import BAutocomplete from "@/components/generic/BAutocomplete";
import BInputHighlightMixin from "@/components/generic/BInputHighlightMixin";
import BSelectWrapperMixin from "@/components/generic/BSelectWrapperMixin";

export default {
  name: "b-autocomplete-subject",
  mixins: [BSelectWrapperMixin, BInputHighlightMixin],
  components: { BAutocomplete },
  data: () => ({
    icons: {
      SECTOR: "fas fa-fw fa-circle",
      SUBSECTOR: "fas fa-fw fa-dot-circle",
      JOB: "far fa-fw fa-dot-circle"
    }
  })
};
</script>

<template>
  <b-autocomplete
    v-model="internalValue"
    v-bind="selectProperties"
    store="b-subject-select"
    :label="multiple ? 'Subjects' : 'Subject'"
    item-text="name"
    prepend-icon="far fa-fw fa-dot-circle"
    autocomplete="different-subjects"
  >
    <template v-slot:selection="{ item, selected }" v-if="multiple">
      <v-chip :selected="selected" small label>
        <v-icon left>{{ icons[item.type] }}</v-icon>
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <v-list-tile-avatar>
        <v-icon>{{ icons[item.type] }}</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title v-html="addSearchHighlightMarkup(item.name)" />
        <v-list-tile-sub-title v-text="item.type" />
      </v-list-tile-content>
    </template>
  </b-autocomplete>
</template>
