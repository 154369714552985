<template>
  <div class="data-provider--table">
    <slot
      :items="internalItems"
      :total-items="totalItems"
      :is-loading="isLoading"
    >
      FEED ME TABLES!!!
    </slot>
  </div>
</template>
<script>
import { createApiUrl } from "@/api/url";
import { clone, isArray, isNil } from "lodash";
import { normalize } from "normalizr";
import Vue from "vue";
import axios from "axios";

export default {
  name: "table-data-provider",
  props: {
    url: {
      type: String,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      default() {
        return {};
      }
    },
    includes: {
      type: Array,
      default() {
        return [];
      }
    },
    joins: {
      type: Array,
      default() {
        return [];
      }
    },
    schema: {
      type: Object,
      required: false
    },
    entityName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      items: [],
      totalItems: undefined,
      isLoading: false,
      filterQuickSearchTimeout: undefined,
      cancelExecutor: undefined
    };
  },
  computed: {
    internalItems() {
      if (!isNil(this.schema)) {
        return clone(
          this.$store.getters["entities/getEntities"]({
            name: this.entityName,
            ids: this.items
          })
        );
      } else {
        return this.items;
      }
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    "filters.search"() {
      clearTimeout(this.filterQuickSearchTimeout);
      this.filterQuickSearchTimeout = setTimeout(() => {
        this.getDataFromApi();
      }, 1000);
    }
  },
  methods: {
    async getDataFromApi() {
      try {
        clearTimeout(this.filterQuickSearchTimeout);

        if (!isNil(this.cancelExecutor)) {
          this.cancelExecutor();
        }
        let CancelToken = axios.CancelToken;
        const url = createApiUrl(this.url, {
          includes: this.includes,
          filters: this.filters,
          pagination: this.pagination,
          joins: this.joins
        });
        const options = {
          cancelToken: new CancelToken(c => {
            this.cancelExecutor = c;
          })
        };

        this.isLoading = true;
        const { data } = await Vue.$http.get(url, options);
        if (isNil(this.schema)) {
          this.items = data.data;
        } else {
          let normalizedData = normalize(data.data, [this.schema]);
          this.$store.commit("entities/updateEntities", {
            entities: normalizedData.entities
          });
          this.items = normalizedData.result;
        }
        this.totalItems = data.meta.total;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
    clearFilters() {
      Object.keys(this.filters).forEach(
        i => (this.filters[i] = isArray(this.filters[i]) ? [] : null)
      );
    }
  }
};
</script>
