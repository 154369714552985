<template>
  <div class="d-flex">
    <b-select
      class="flex-1 pr-3"
      v-model="type"
      label="Question"
      :items="[
        { text: 'Any', value: 'ANY' },
        { text: 'At least', value: 'AT_LEAST' },
        { text: 'Exact', value: 'EXACT' },
        { text: 'Except', value: 'EXCEPT' }
      ]"
      required
      :validate-name="`${validationPrefix}.type`"
    />
    <b-select
      class="flex-9 pr-3"
      v-model="options"
      :label="ruleTriggerQuestion.question.label[authUserLocaleCode]"
      :items="ruleTriggerQuestion.question.options"
      :item-text="`label.${authUserLocaleCode}`"
      multiple
      :required="
        ['ANY', 'AT_LEAST', 'EXCEPT'].includes(ruleTriggerQuestion.type)
      "
      :validate-name="`${validationPrefix}.options`"
      return-object
    />
    <div class="flex-1 text-xs-center">
      <v-btn @click="remove">REMOVE</v-btn>
    </div>
  </div>
</template>
<script type="text/babel">
import BSelect from "@/components/generic/BSelect";
import AuthUserLocaleMixin from "@/components/generic/AuthUserLocaleMixin";
import {
  REMOVE_RULE_TRIGGER_QUESTION,
  SET_RULE_TRIGGER_QUESTION_OPTIONS,
  SET_RULE_TRIGGER_QUESTION_TYPE
} from "@/store/modules/rule-trigger-groups/mutation-types";

export default {
  name: "submission-trigger-question",
  mixins: [AuthUserLocaleMixin],
  components: { BSelect },
  inject: ["$validator"],
  props: {
    store: {
      type: String,
      required: true
    },
    ruleTrigger: {
      type: Object,
      required: true
    },
    ruleTriggerQuestion: {
      type: Object,
      required: true
    },
    validationPrefix: {
      type: String,
      required: true
    }
  },
  computed: {
    type: {
      get() {
        return this.ruleTriggerQuestion.type;
      },
      set(value) {
        this.$store.commit(
          `${this.store}/ruleTriggerGroups/${SET_RULE_TRIGGER_QUESTION_TYPE}`,
          {
            question: this.ruleTriggerQuestion,
            type: value
          }
        );
      }
    },
    options: {
      get() {
        return this.ruleTriggerQuestion.options;
      },
      set(newValue) {
        this.$store.commit(
          `${this.store}/ruleTriggerGroups/${SET_RULE_TRIGGER_QUESTION_OPTIONS}`,
          {
            question: this.ruleTriggerQuestion,
            options: newValue
          }
        );
      }
    }
  },
  methods: {
    remove() {
      this.$store.commit(
        `${this.store}/ruleTriggerGroups/${REMOVE_RULE_TRIGGER_QUESTION}`,
        { ruleTrigger: this.ruleTrigger, question: this.ruleTriggerQuestion }
      );
    }
  }
};
</script>
