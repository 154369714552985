<template>
  <tr>
    <td>{{ separationRule.name }}</td>
    <td>
      {{ separationRule.type }}
    </td>
    <td>
      <v-chip v-for="group in submissionGroups" :key="group.id" label>
        <div>
          {{ subjectNamesByGroup(group).join(", ") }}
        </div>
      </v-chip>
    </td>
    <td class="text-sm-right">
      <v-icon v-if="separationRule.isActive" color="green">
        check
      </v-icon>
      <v-icon v-else color="grey">
        clear
      </v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'separation-rules.show',
          params: { separationRuleId: separationRule.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
export default {
  name: "separation-rule-table-row",
  props: {
    separationRuleId: {
      type: Number,
      required: true
    }
  },
  computed: {
    separationRule() {
      return this.$store.getters["entities/getEntity"]({
        name: "separationRule",
        id: this.separationRuleId
      });
    },
    submissionGroups() {
      return this.$store.getters["entities/getEntities"]({
        name: "separationRuleGroup",
        ids: this.separationRule.groups
      });
    },
    subjectsByGroup(group) {
      return this.$store.getters["entities/getEntities"]({
        name: "subjects",
        ids: group.subjects
      });
    },
    subjectNamesByGroup(group) {
      return this.subjectsByGroup(group).map(subject => subject.name);
    }
  }
};
</script>
