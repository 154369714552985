<template>
  <div
    :class="{
      'submission-trigger': true,
      'pos-relative border--width-2 pa-3 elevation-1': true,
      'white secondary--border': !hasMatchData,
      'success lighten-1 success--border': hasMatchData && isMatched,
      'error lighten-1 error--border': hasMatchData && !isMatched
    }"
  >
    <v-btn
      class="separation-rule-group--delete-btn"
      icon
      absolute
      right
      top
      @click="$emit('remove', { trigger })"
    >
      <v-icon color="secondary">
        far fa-fw fa-trash-alt
      </v-icon>
    </v-btn>
    <!-- SUBMISSION TRIGGER TYPE -->
    <div class="submission-trigger-type">
      <div class="d-flex">
        <div class="flex-5 pr-3">
          <b-select
            v-model="type"
            :items="triggerTypes"
            label="Type"
            required
            :validate-name="`${validationPrefix}.type`"
            :class="{
              success: hasMatchData && isTypeMatched,
              error: hasMatchData && !isTypeMatched
            }"
          />
        </div>
        <div class="flex-5 pr-3">
          <b-select
            v-if="isSpecifierActive"
            v-model="specifier"
            :items="specifiers"
            label="Specifiers"
            :required="isSpecifierActive"
            :validate-name="`${validationPrefix}.specifier`"
          />
        </div>
        <div class="flex-1">
          <b-switch
            v-model="isInverted"
            label="Invert"
            :validate-name="`${validationPrefix}.isInverted`"
          />
        </div>
      </div>
      <div class="d-flex" v-if="isValueActive">
        <div class="flex-10 pr-3">
          <v-combobox
            v-model="value"
            :items="[]"
            label="Value"
            chips
            deletable-chips
            tags
            multiple
            small
            :append-icon="null"
            :required="isValueActive"
            :validate-name="`${validationPrefix}.value`"
          />
        </div>
        <div class="flex-1">
          <b-switch
            v-model="isCaseSensitive"
            label="Case sensitive"
            :validate-name="`${validationPrefix}.isCaseSensitive`"
          />
        </div>
      </div>
      <!-- END SUBMISSION TRIGGER TYPE -->
    </div>
    <!-- END SUBMISSION TRIGGER TYPE -->
    <div class="d-flex">
      <div class="flex-10 pr-3">
        <b-autocomplete-subject
          v-model="subjects"
          multiple
          :validate-name="`${validationPrefix}.subjects`"
          :class="{
            success: hasMatchData && isSubjectsMatched,
            error: hasMatchData && !isSubjectsMatched
          }"
        />
      </div>
      <div class="flex-1">
        <b-select
          v-model="subjectsType"
          :items="['any', 'any_exclusive', 'all', 'all_exclusive', 'none']"
          label="Subjects type"
          :clearable="false"
        />
      </div>
    </div>

    <submission-trigger-inverter v-model="isLanguagesInverted" name="Language">
      <template v-slot:default="{ hint }">
        <b-autocomplete-language
          v-model="languages"
          multiple
          :hint="hint"
          :validate-name="`${validationPrefix}.languages`"
          :class="{
            success: hasMatchData && isLanguagesMatched,
            error: hasMatchData && !isLanguagesMatched
          }"
        />
      </template>
    </submission-trigger-inverter>
    <submission-trigger-inverter v-model="isCountriesInverted" name="Country">
      <template v-slot:default="{ hint }">
        <b-autocomplete-country
          v-model="countries"
          multiple
          :hint="hint"
          :validate-name="`${validationPrefix}.countries`"
          :class="{
            success: hasMatchData && isCountriesMatched,
            error: hasMatchData && !isCountriesMatched
          }"
        />
      </template>
    </submission-trigger-inverter>
    <submission-trigger-questions
      :store="store"
      :rule-trigger="trigger"
      :rule-trigger-questions="questions"
      :validation-prefix="`${validationPrefix}.triggerQuestions`"
    />
  </div>
</template>
<script type="text/babel">
import { includes, isNil } from "lodash";
import SubmissionTriggerMixin from "./SubmissionTriggerMixin";
import SubmissionTriggerQuestions from "@/components/submission-trigger/SubmissionTriggerQuestions";
import BSelect from "@/components/generic/BSelect";
import BSwitch from "@/components/generic/BSwitch";
import BAutocompleteLanguage from "@/components/language/BAutocompleteLanguage";
import BAutocompleteCountry from "@/components/country/BAutocompleteCountry";
import BAutocompleteSubject from "@/components/subject/BAutocompleteSubject";
import SubmissionTriggerInverter from "@/components/submission-trigger/SubmissionTriggerInverter";

export default {
  name: "submission-trigger",
  inject: ["$validator"],
  components: {
    SubmissionTriggerInverter,
    BAutocompleteSubject,
    BAutocompleteCountry,
    BAutocompleteLanguage,
    BSwitch,
    BSelect,
    SubmissionTriggerQuestions
  },
  mixins: [SubmissionTriggerMixin],
  props: {
    store: {
      type: String,
      required: true
    },
    triggerGroup: {
      type: Object,
      required: true
    },
    trigger: {
      type: Object,
      required: true
    },
    triggerGroupIndex: {
      type: Number,
      required: true
    },
    triggerIndex: {
      type: Number,
      required: true
    },
    matchResults: {
      type: Array,
      default: undefined
    },
    entityName: {
      type: String,
      required: true
    }
  },
  computed: {
    hasMatchData() {
      return this.matchResults !== undefined;
    },
    isMatched() {
      return (
        this.hasMatchData &&
        this.matchResults.some(({ isMatched }) => isMatched)
      );
    },
    isTypeMatched() {
      return (
        this.hasMatchData &&
        this.matchResults.some(({ isTypeMatched }) => isTypeMatched)
      );
    },
    isCountriesMatched() {
      return (
        this.hasMatchData &&
        this.matchResults.some(({ isCountriesMatched }) => isCountriesMatched)
      );
    },
    isLanguagesMatched() {
      return (
        this.hasMatchData &&
        this.matchResults.some(({ isLanguagesMatched }) => isLanguagesMatched)
      );
    },
    isSubjectsMatched() {
      return (
        this.hasMatchData &&
        this.matchResults.some(({ isSubjectsMatched }) => isSubjectsMatched)
      );
    },
    isSpecifierActive() {
      return includes(["CONTAINS", "CHAR_COUNT"], this.type);
    },
    isValueActive() {
      return includes(
        ["INCLUDES", "EXACT_MATCH", "STARTS_WITH", "ENDS_WITH", "CHAR_COUNT"],
        this.type
      );
    },
    isValueMultiple() {
      return this.type !== "CHAR_COUNT" || isNil(this.specifiers);
    },
    triggerTypes() {
      return Object.entries(window.config.enums.submission_triggers.types).map(
        o => ({
          text: o[1],
          value: o[0]
        })
      );
    },
    triggerTargets() {
      return Object.entries(
        window.config.enums.submission_triggers.targets
      ).map(o => ({
        text: o[1],
        value: o[0]
      }));
    },
    specifiers() {
      if (this.type === "CONTAINS") {
        return Object.entries(
          window.config.enums.submission_triggers.specifiers.CONTAINS
        ).map(o => ({
          text: o[1],
          value: o[0]
        }));
      } else if (this.type === "CHAR_COUNT") {
        return Object.entries(
          window.config.enums.submission_triggers.specifiers.CHAR_COUNT
        ).map(o => ({
          text: o[1],
          value: o[0]
        }));
      }
      return [];
    },
    validationPrefix() {
      return `triggerGroups.${this.triggerGroupIndex}.triggers.${this.triggerIndex}`;
    },
    questions() {
      return this.trigger.triggerQuestions;
    }
  },
  watch: {
    "trigger.type"() {
      this.specifier = null;
    }
  }
};
</script>
<style scoped>
.separation-rule-group--delete-btn {
  top: 0.25rem;
  right: 0.25rem;
}
</style>
