export const SET_RULE_TRIGGER_GROUPS = "setRuleTriggerGroups";

export const SET_RULE_TRIGGER_GROUP_MATCH_DATA = "setRuleTriggerGroupMatchData";

export const ADD_RULE_TRIGGER_TO_GROUP = "addRuleTriggerToGroup";
export const ADD_RULE_TRIGGER_TO_NEW_GROUP = "addRuleTriggerToNewGroup";
export const REMOVE_RULE_TRIGGER_FROM_GROUP = "removeRuleTriggerFromGroup";
export const REMOVE_RULE_TRIGGER_GROUP = "removeRuleTriggerGroup";

export const SET_RULE_TRIGGER_GROUP_TARGETS = "setRuleTriggerGroupTargets";

export const SET_RULE_TRIGGER_TYPE = "setRuleTriggerType";
export const SET_RULE_TRIGGER_IS_INVERTED = "setRuleTriggerIsInverted";
export const SET_RULE_TRIGGER_TARGETS = "setRuleTriggerTargets";
export const SET_RULE_TRIGGER_VALUE = "setRuleTriggerValue";
export const SET_RULE_TRIGGER_IS_CASE_SENSITIVE =
  "setRuleTriggerIsCaseSensitive";
export const SET_RULE_TRIGGER_SPECIFIER = "setRuleTriggerSpecifier";
export const SET_RULE_TRIGGER_COUNTRIES = "setRuleTriggerCountries";
export const SET_RULE_TRIGGER_IS_COUNTRIES_INVERTED =
  "setRuleTriggerIsCountriesInverted";
export const SET_RULE_TRIGGER_LANGUAGES = "setRuleTriggerLanguages";
export const SET_RULE_TRIGGER_IS_LANGUAGES_INVERTED =
  "setRuleTriggerIsLanguagesInverted";
export const SET_RULE_TRIGGER_SUBJECTS = "setRuleTriggerSubjects";
export const SET_RULE_TRIGGER_IS_SUBJECTS_TYPE = "setRuleTriggerIsSubjectsType";

export const REMOVE_RULE_TRIGGER_QUESTION = "removeRuleTriggerQuestion";
export const ADD_RULE_TRIGGER_QUESTION = "addRuleTriggerQuestion";
export const SET_RULE_TRIGGER_QUESTION_TYPE = "setRuleTriggerQuestionType";
export const SET_RULE_TRIGGER_QUESTION_OPTIONS =
  "setRuleTriggerQuestionOptions";
