<script type="text/babel">
import { isNil, replace } from "lodash";

export default {
  name: "b-input-highlight-mixin",
  methods: {
    addSearchHighlightMarkup(text) {
      if (isNil(text) || isNil(this.search)) {
        return text;
      }
      return replace(
        text,
        new RegExp(this.search, "i"),
        capture => `<span class="v-list__tile__mask">${capture}</span>`
      );
    }
  }
};
</script>
